import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import instagramLogo from "../images/moonkoalas-instagram.png";
import facebookLogo from "../images/moonkoalas-facebook.png";
import pinterestLogo from "../images/moonkoalas-pinterest.png";


function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`mascarillas bebe`, `mascarillas niña`, `mascarillas niño`, `ropa de proximidad`, `moda sostenible`, `bebe`, `moonkoalas`, `OEKO-TEX`]}
        title="Moonkoalas Links"
      />

        <section className="text-center">
          <div className="relative h-24 overflow-hidden">
            <img className="block w-1/2 mx-auto mb-8" src={`../../moonkoalas.png`} alt="moonkoalas" />
          </div>
        </section>

        <section className="text-center">
 
          <div className="h-24">
            <a href="https://moonkoalas.com/collections/mascarillas-infantiles-tela?utm_source=landing-instagram&utm_medium=social&utm_campaign=masks" rel="noopener noreferrer" target="_blank">
              <button className="flex-1 w-11/12 bg-transparent hover:bg-white text-white font-semibold hover:text-gray-500 py-4 border-2 border-white hover:border-white">
                MASCARILLAS INFANTIL Y ADULTO
              </button>
            </a>
          </div>

          <div className="h-24">
            <a href="https://moonkoalas.com/collections/conjuntos?utm_source=landing-instagram&utm_medium=social&utm_campaign=conjuntos" rel="noopener noreferrer" target="_blank">
            <button className="flex-1 w-11/12 bg-transparent hover:bg-white text-white font-semibold hover:text-gray-500 py-4 border-2 border-white hover:border-white">
                CONJUNTOS 
              </button>
            </a>
          </div>

          <div className="h-24">
            <a href="https://moonkoalas.com/collections/ranitas?utm_source=landing-instagram&utm_medium=social&utm_campaign=ranitas" rel="noopener noreferrer" target="_blank">
            <button className="flex-1 w-11/12 bg-transparent hover:bg-white text-white font-semibold hover:text-gray-500 py-4 border-2 border-white hover:border-white">
                RANITAS 
              </button>
            </a>
          </div>

          <div className="h-24">
            <a href="https://moonkoalas.com/collections/vestidos-nina?utm_source=landing-instagram&utm_medium=social&utm_campaign=vestidos" rel="noopener noreferrer" target="_blank">
            <button className="flex-1 w-11/12 bg-transparent hover:bg-white text-white font-semibold hover:text-gray-500 py-4 border-2 border-white hover:border-white">
                VESTIDOS 
              </button>
            </a>
          </div>

          <div className="h-24">
            <a href="https://moonkoalas.com/collections/outlet?utm_source=landing-instagram&utm_medium=social&utm_campaign=outlet" rel="noopener noreferrer" target="_blank">
            <button className="flex-1 w-11/12 bg-transparent hover:bg-white text-white font-semibold hover:text-gray-500 py-4 border-2 border-white hover:border-white">
                OUTLET 
              </button>
            </a>
          </div>

          < br/>< br/>
          <a href="https://instagram.com/moonkoalas" className="inline-block align-middle py-2" rel="noopener noreferrer" target="_blank">
          <img
          alt="Instagram Moonkoalas"
          className="block w-16 mx-auto mb-8 px-3"
          src={instagramLogo}
          />
          </a>
          
          <a href="https://facebook.com/moonkoalas" className="inline-block align-middle py-2" rel="noopener noreferrer" target="_blank">
          <img
          alt="Facebook Moonkoalas"
          className="block w-16 mx-auto mb-8 px-3"
          src={facebookLogo}
          />
          </a>

          <a href="https://pinterest.es/moonkoalas" className="inline-block align-middle py-2" rel="noopener noreferrer" target="_blank">
          <img
          alt="Pinterest Moonkoalas"
          className="block w-16 mx-auto mb-8 px-3"
          src={pinterestLogo}
          />
          </a>

          </section>

    </Layout>
  );
}

export default IndexPage;
